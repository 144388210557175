import { Col, message, Modal, Progress, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { getVerticalList, register } from '../../Utils/ApiActions'
import Turnstile, { useTurnstile } from "react-turnstile";
const { Title, Text, Paragraph, Link } = Typography


const Step3 = (props) => {
    function focusOut() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('lockr-form-control-focus');
        }
    }
    function getFocus() {
        let elements = document.getElementsByTagName("input");

        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('lockr-form-control-focus');
        }
    }

    const turnstile = useTurnstile();

    const [bName, setBName] = useState('')
    const [vertical, setVertical] = useState('');
    const [website, setWebsite] = useState('')
    const [mobile, setMobile] = useState('')
    const [displayLoading, setDisplayLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [verticleOptions, setVerticleOptions] = useState([]);
    const [registered, setRegistered] = useState(false)
    const [captchaToken, setCaptchaToken] = useState()



    const urlValidation = new RegExp(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/, 'gmi');
    let mobileRegEx = new RegExp("[0-9]{9,14}");

    const _signUpDetails = props?.location?.state?.signUpDetails

    useEffect(() => {
        if (props?.location?.state === undefined || _signUpDetails === undefined) {
            props.history.replace('/console/signup')
        }
        getVerticalList()
            .then(response => {

                setVerticleOptions(response.data)
            })
            .catch(error => {
                console.log(error);
            })

    }, [])





    function checkValidation() {
        let _validate = true
        let _urlRegEx = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi);
        let _mobileRegEx = new RegExp("[0-9]{9,14}");
        if (bName.trim() === '') {
            setError(true)
            setErrorText("Business Name Can't be blank");
            return _validate = false
        }
        if (website.trim() === '' || !_urlRegEx.test(website)) {
            setError(true)
            setErrorText("Invalid URL");
            return _validate = false
        }
        if (mobile.trim() !== '' || !_mobileRegEx.test(mobile)) {
            setError(true)
            setErrorText("Invalid phone number");
            return _validate = false
        }
        return _validate
    }

    const handleNextClick = () => {
        if (checkValidation()) {
            setDisplayLoading(true)
            props.history.push('/console/signup/step4', {
                signUpDetails: {
                    email: _signUpDetails.email,
                    firstName: _signUpDetails.fname,
                    lastName: _signUpDetails.lname,
                    businessName: bName,
                    businessURL: website,
                    businessPhone: mobile,
                    businessVertical: vertical.id
                }
            })
        }
    }


    const handleRegister = (reviewDetails) => {
        setDisplayLoading(true)
        const signUpDetails = {
            email: _signUpDetails.email,
            firstName: _signUpDetails.fname,
            lastName: _signUpDetails.lname,
            businessName: bName,
            businessURL: website,
            businessPhone: mobile,
            businessVertical: vertical.id,
            captchaToken
        }
        // _signUpDetails['isLicenseAgreed'] = agreement
        // _signUpDetails['address'] = reviewDetails.address;
        console.log("signUpDetails", signUpDetails.businessPhone)
        register(signUpDetails).then(response => {
            console.log("🚀 ~ register ~ response:", response)
            setDisplayLoading(false)
            if (response.success) {
                // acceptAgreement({...reviewDetails, publisherID: response.publisherID});
                setRegistered(true)
                setError(false)

            } else {
                message.error("Unable to register account due to: " + response?.error?.message);
            }
        }).catch(err => {
            setDisplayLoading(false)
            setError(false)
            // message.error("Unable to register account due to: " + err.message);
        })
    }

    document.body.classList.add('lockr-bg-img');
    return (
      <Spin spinning={displayLoading}>
        <Row>
          <Progress className="reg-steps" percent={75} showInfo={false} />
        </Row>
        <Row justify="start" className="reg-container">
          <Row style={{ width: "100%" }}>
            <Col>
              <img
                className="lockr-logo"
                src={require("../../assets/onboard_lock.42d74f50.png")}
                alt="lock-img"
              />
            </Col>
          </Row>
          <RegCommonTitle title={"Tell us about your business"} />
          <Row className="lockr-join2plus" style={{ marginTop: 48 }}>
            <Col sm={14} xs={24}>
              <FloatLabel value={bName} label={"Business name *"}>
                <RegCommonInput
                  className={error ? "errorInputText" : null}
                  value={bName}
                  onChange={(event) => {
                    setBName(event.target.value);
                    setError(false);
                    setErrorText("");
                  }}
                  onFocus={(event) => {
                    getFocus();
                  }}
                  onBlur={(event) => {
                    focusOut();
                  }}
                />
              </FloatLabel>
            </Col>

            <Col className="lockr-website-control" sm={14} xs={24}>
              <FloatLabel value={website} label={"Website URL *"}>
                <RegCommonInput
                  captureTab
                  className={error ? "errorInputText" : null}
                  value={website}
                  onChange={(event) => {
                    setWebsite(event.target.value);
                    setError(false);
                    setErrorText("");
                  }}
                  onFocus={(event) => {
                    getFocus();
                  }}
                  onBlur={(event) => {
                    focusOut();
                  }}
                />
              </FloatLabel>
            </Col>
            <Col sm={14} xs={24}>
              <FloatLabel value={vertical.name} label={"Business vertical *"}>
                <RegCommonInput
                  verticleOptions={verticleOptions}
                  isDropDown
                  className={error ? "errorInputText" : null}
                  value={vertical.name}
                  onChange={(event) => {
                    setVertical(event);
                    setError(false);
                    setErrorText("");
                  }}
                  onFocus={(event) => {
                    getFocus();
                  }}
                  onBlur={(event) => {
                    focusOut();
                  }}
                />
              </FloatLabel>
            </Col>
            <Col sm={14} xs={24}>
              <RegCommonInput
                isPhoneNumber
                className={error ? "errorInputText" : null}
                type={"text"}
                value={mobile}
                onChange={(event) => {
                  setMobile(event);
                  setError(false);
                  setErrorText("");
                }}
                onFocus={(event) => {
                  getFocus();
                }}
                onBlur={() => {
                  focusOut();
              }}
              />
            </Col>
            {error && (
              <Col sm={14} xs={24}>
                <label className="errorText">{errorText}</label>
              </Col>
            )}
          </Row>
          <div style={{ marginTop: 20 }}>
            <Turnstile
              sitekey="0x4AAAAAAAyrxE-xT0wJuV19"
              refreshExpired="auto"
              onVerify={(token) => {
                setCaptchaToken(token)}
              }
              onExpire={() => {
                setCaptchaToken()}
              }
              onTimeout={() => {
                setCaptchaToken()}
              }
            />
          </div>
          <RegCommonButton
            disabled={
              bName === "" || !urlValidation.test(website) || vertical === "" || !captchaToken || !mobileRegEx.test(mobile)
            }
            btntext={"Sign up"}
            onClick={handleRegister}
          />
        </Row>

        {registered && (
          <Modal
            width={716}
            style={{ height: 331 }}
            className="verificationModal"
            bodyStyle={{
              paddingTop: 0,
              backgroundColor: "#1d1d1d",
              paddingRight: 60,
              paddingLeft: 60,
            }}
            title={
              <Title
                style={{
                  fontSize: 44,
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 700,
                  marginBottom: 0,
                }}
              >
                Profile Under Review
              </Title>
            }
            footer={null}
            visible={registered}
            maskClosable={false}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.85)" }}
            closable={false}
          >
            <Row style={{ width: "100%" }}>
              <Paragraph>
                Thank you for registering! We are currently reviewing your
                profile. Please keep an eye on your inbox – we'll send you an
                email with further instructions soon.
              </Paragraph>
            </Row>
            <Row style={{ width: "100%" }}>
              <Text italic>
                Note: This process may take up to 24 hours. Be sure to check
                your spam folder if you don't see an email.
              </Text>
            </Row>
            <Row style={{ width: "100%", marginTop: 48 }}>
              <Col span={12}>
                <Link
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#2684f8",
                  }}
                  href={"https://loc.kr/contact/"}
                  target="_blank"
                >
                  Need help?
                </Link>
              </Col>
            </Row>
          </Modal>
        )}
      </Spin>
    );
}

export default withRouter(Step3)
import { useEffect, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Col, Row, Table, Typography } from "antd";
import { CreateAwsModal } from "../../Components/CreateAWSModal";
import { createNewGenericConnection, deleteGenericConnection, getAllGenericConnections, updateGenericConnection } from "../../Utils/ApiActions";
import { mapSnowflakStatusToUI, mapSnowflakeStatusToColor } from "../../Utils/Helper";
import moment from "moment";
import DeleteTypeModal from "../../Components/LockrDeleteModal";
import { ReportModal } from "../SelfServe/SelfServe";

export function AWSConnections() {
    const [displayLoading, setDisplayLoading] = useState(false);
    const [awsConnections, setAwsConnections] = useState([]);
    const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
    const [formMode, setFormMode] = useState('NEW');
    const [selectedConnectionDetails, setSelectedConnectionDetails] = useState(undefined);
    const [selectedConnectionToDelete, setSelectedConnectionToDelete] = useState(undefined);
    const [selectedConnectionIDForReport, setSeleectedConnectionIDForReport] = useState(undefined);

    function getJSONConfigurationForColumn(record) {
        return JSON.parse(record.configuration);
    }

    function renderStatus(status) {
        return (
            <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ height: 10, width: 10, borderRadius: 20, background: mapSnowflakeStatusToColor(status) }} />
                <label className='connection-tile-font' style={{ marginLeft: 8 }}>{mapSnowflakStatusToUI(status)}</label>
            </Row>
        )
    }

    function checkIfEditDisabled(record) {
        return record.status === 'PROCESSING' || record.status === 'SENT_TO_EMAILCHECKER' || record.status === 'IN_PROGRESS' || record.status === 'FAILED' || record.status === 'COMPLETED'
    }

    function displayStopOrDeleteButton(record) {
        if (record.status === 'PROCESSING' || record.status === 'SENT_TO_EMAILCHECKER') {
            return (
                <img alt='stop' onClick={() => {
                    // stopConnectionAPICall(record.id);
                }} src={require('../../assets/stop_icon.png')} style={{
                    marginLeft: 16,
                    marginRight: 16,
                    cursor: 'pointer',
                    width: 16,
                }} />
            )
        } else {
            return (
                <img alt='delete' onClick={() => {
                    setSelectedConnectionToDelete(record.id);
                }} src={require('../../assets/remove_icon.png')} style={{
                    marginLeft: 16,
                    marginRight: 16,
                    cursor: 'pointer',
                    width: 16,
                }} />
            )
        }
    }

    function renderActionButtons(record) {
        return (
            <Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button onClick={() => {
                    setFormMode('DUPLICATE');
                    setSelectedConnectionDetails(record);
                    setShowNewConnectionModal(true);
                }} style={{ height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img style={{ marginRight: 8, cursor: 'pointer' }} alt="duplicate" src={require('../../assets/duplicate_icon.png')} />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>Duplicate</label>
                </Button>
                <Button className='infosum-edit-button' disabled={checkIfEditDisabled(record)} onClick={() => {
                    if (record.status !== 'PROCESSING' && record.status !== 'SENT_TO_EMAILCHECKER') {
                        setFormMode('EDIT');
                        setSelectedConnectionDetails(record);
                        setShowNewConnectionModal(true);
                    }
                }} style={{ marginLeft: 8, height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={checkIfEditDisabled(record) ? require('../../assets/edit_disabled.png') : require('../../assets/edit_icon.png')}
                        style={{ marginRight: 8, cursor: 'pointer', height: 16, width: 16 }}
                    />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>Edit</label>
                </Button>
                <Button onClick={() => {
                    setSeleectedConnectionIDForReport(record.id)
                }} style={{ marginLeft: 8, height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={require('../../assets/report_icon.png')}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                    />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>View Report</label>
                </Button>
                {displayStopOrDeleteButton(record)}
            </Row>
        )
    }

    const awsListColumns = [{
        title: <span className="app-table-header">S3 Bucket</span>,
        width: '35%',
        render: (_, record) => <label className="connection-tile-font">{getJSONConfigurationForColumn(record).s3URL}</label>
    }, {
        title: <span className="app-table-header">Access Key</span>,
        align: 'right',
        render: (_, record) => <label className="connection-tile-font">{getJSONConfigurationForColumn(record).accessKey}</label>
    }, {
        title: <span className="app-table-header">Date/Time</span>,
        render: (_, record) => <label className="connection-tile-font">{record.createdAt ? moment(record.createdAt).format('MM/DD/YYYY') : ''}</label>,
        align: 'right'
    }, {
        title: <span className="app-table-header">Status</span>,
        align: 'right',
        render: (_, record) => <label className="connection-tile-font">{renderStatus(record.status)}</label>
    }, {
        title: <span className="app-table-header">Actions</span>,
        align: 'right',
        render: (_, record) => renderActionButtons(record)
    }]

    useEffect(() => {
        fetchAWSConnections();
    }, []);

    async function fetchAWSConnections() {
        try {
            setDisplayLoading(true);
            const connectionsResponse = await getAllGenericConnections('AWS');
            if (connectionsResponse.success && connectionsResponse.data.length) {
                setAwsConnections(connectionsResponse.data)
            }
        } catch (error) {
            console.log(`Unable to get the aws connections`);
            console.log(error);

        }
        setDisplayLoading(false);
    }

    function renderTable() {
        if (awsConnections.length > 0) {
            return (
                <div style={{
                    background: 'black', marginLeft: 90, marginRight: 90, padding: 20
                }}>
                    <div className="connection-list-table">
                        <Table
                            style={{ background: 'black' }}
                            loading={displayLoading}
                            rowKey={"id"}
                            dataSource={awsConnections}
                            columns={awsListColumns}
                            pagination={false}
                        />
                    </div>
                </div>
            )
        }
    }

    async function deleteConnectionAPICall() {
        try {
            await deleteGenericConnection(selectedConnectionToDelete);
            setSelectedConnectionToDelete(undefined);
            fetchAWSConnections();
        } catch (error) {
            console.log(`Unable to delete the connection at the moment`);
            console.log(error);
        }
    }

    function renderEmptySection() {
        if (!displayLoading && awsConnections.length <= 0) {
            return (
                <div style={{
                    marginLeft: 90,
                    marginRight: 90,
                    minHeight: '50vh',
                    background: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <img alt='logo_grey' src={require('../../assets/logo_grey.png')} />
                    <div style={{ marginTop: 42, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography.Text style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '24px',
                            color: '#fff'
                        }}>Connect lockr with AWS</Typography.Text>
                        <Button
                            style={{ marginTop: 24, width: '217px' }}
                            type='primary'
                            className='btn-first-connection'
                            onClick={() => {
                                setShowNewConnectionModal(true);
                            }}>New Analysis</Button>
                    </div>
                </div>
            )
        }
    }

    async function createGenericConnectionAPI(connectionDetails) {
        try {
            let response = undefined;
            if (formMode === 'EDIT') {
                response = await updateGenericConnection(selectedConnectionDetails.id, 'AWS', JSON.stringify(connectionDetails));
            } else {
                response = await createNewGenericConnection(JSON.stringify(connectionDetails), 'AWS');
            }
            if (response.success) {
                setSelectedConnectionDetails(undefined)
                setShowNewConnectionModal(false);
                fetchAWSConnections();
                setFormMode('NEW')
                return;
            }
        } catch (error) {
            console.log(`Unable to create new AWS connection.`)
        }
    }

    return (
        <SideDrawer>
            <Row
                justify="space-between"
                align="middle"
                className="apiandservices-newapp-btn"
            >
                <Col sm={17} xs={24}>
                    <Typography.Title className="apiandservices-heading">AWS</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            AWS S3 to integrate the details
                        </label>
                    </div>
                </Col>
                <Col sm={7} xs={24} className="createAppButton">
                    <Button
                        style={{ width: '217px' }}
                        type="primary"
                        disabled={false}
                        className="sign-in"
                        onClick={() => { setShowNewConnectionModal(true); }}
                    >New Analysis</Button>
                </Col>
            </Row>
            {renderEmptySection()}
            {renderTable()}
            {showNewConnectionModal && <CreateAwsModal
                display={showNewConnectionModal}
                defaultConnectionDetails={selectedConnectionDetails?.configuration ? JSON.parse(selectedConnectionDetails.configuration) : undefined}
                isEdit={formMode === 'EDIT'}
                isDuplicate={formMode === 'DUPLICATE'}
                onSave={(connectionDetails) => {
                    createGenericConnectionAPI(connectionDetails)
                }}
                onCancel={() => {
                    setShowNewConnectionModal(false);
                    setFormMode('NEW');
                    setSelectedConnectionDetails(undefined)
                }}
            />}
            {!!selectedConnectionIDForReport && <ReportModal 
                visible={!!selectedConnectionIDForReport}
                onCancel={()=>{
                    setSeleectedConnectionIDForReport(undefined);
                }}
                connectionID={selectedConnectionIDForReport}
                isFromConnection
            />}
            {!!selectedConnectionToDelete && <DeleteTypeModal
                visible={!!selectedConnectionToDelete}
                handleOk={() => {
                    deleteConnectionAPICall();
                }}
                handleCancel={() => {
                    setSelectedConnectionToDelete(undefined)
                }}
                title={"Delete?"}
            ></DeleteTypeModal>}
        </SideDrawer>
    )

}
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Dropdown
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import { listAllApplications } from "../../../Utils/ApiActions";

export function AppSelectionDropdown({ value, onChange, size='large' }) {
    const [appList, setAppList] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);
    const [displayCustomFilter, setDisplayCustomFilter] = useState(false);
  
    useEffect(() => {
      getApplications();
    }, []);
  
    useEffect(() => {
      value?.length && setSelectedApps(value);
    }, [value]);
  
    async function getApplications() {
      try {
        const response = await listAllApplications();
        if (response.data?.length) {
          setAppList(response.data);
          !value?.length && onChange(response.data?.map(item => item.id));
        }
      } catch (error) {
        console.log("Unable to get the list of the apps");
        console.log(error);
      }
    }
  
    
    const menu = (
      <div className="identity-provider-options-dropdown">
        <div
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            setSelectedApps(appList?.map(item => item.id));
            onChange(appList?.map(item => item.id));
            // setDisplayCustomFilter(false);
          }}
        >
          <AiOutlineCheck
            style={{
              color: selectedApps?.length > 0 && selectedApps?.length !== appList?.length ? "transparent" : "#61CEF7",
              marginRight: 8,
            }}
          />
          <span
            style={{
              color: selectedApps?.length == appList?.length ? "#61cef7" : "white",
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontSize: 14,
            }}
          >
            All
          </span>
        </div>
        {appList?.map((item) => {
          return (
            <div
              style={{
                marginTop: 10,
              }}
              onClick={() => {
                if (!selectedApps?.includes(item.id)) {
                  setSelectedApps([...selectedApps, item.id]);
                  onChange([...selectedApps, item.id]);
                } else {
                  let selectedAppList = [...selectedApps];
                  if(selectedAppList?.length == appList?.length){
                    selectedAppList = [item.id];
                  }else if(selectedAppList?.length != 1){ 
                    selectedAppList.splice(selectedAppList.indexOf(item.id), 1);
                  }
                  setSelectedApps(selectedAppList);
                  onChange(selectedAppList);
                }
              }}
            >
              <AiOutlineCheck
                style={{
                  color: selectedApps?.includes(item.id) && selectedApps?.length !== appList?.length ? "#61CEF7" : "transparent",
                  marginRight: 8,
                }}
              />
              <span
                style={{
                  color: selectedApps?.includes(item.id) && selectedApps?.length !== appList?.length ? "#61cef7" : "white",
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                  fontSize: 14,
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  
    return (
        <Dropdown
          overlayStyle={{minWidth: 0}}
          visible={displayCustomFilter}
          onClick={() => {
            setDisplayCustomFilter(true);
          }}
          onVisibleChange={event => {
            setDisplayCustomFilter(event);
          }}
          destroyPopupOnHide={false}
          placement="bottomLeft"
          trigger={["click"]}
          overlay={menu}
        >
            <Button
              className={`custom-view-more-options ${size == 'small' ? 'small-view-more-options' : ''}`}
              style={{
                height: "56px",
                marginRight: 8,
                display: "flex",
                flexDirection: "row",
              }}
              type="primary"
              shape="default"
            >
              <div>{selectedApps?.length > 0 && selectedApps?.length !== appList?.length ? "Custom" : "All"} </div>
              <div>
                <AiOutlineDown
                  style={{ marginLeft: 12, paddingTop: 4 }}
                ></AiOutlineDown>
              </div>
            </Button>
        </Dropdown>
    );
  }
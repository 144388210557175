import React, { useState } from 'react';
import { useEffect } from 'react';
import SideDrawer from '../../Components/SideDrawer';
import { TitleText } from '../../Components/NewAppModal';
import { Button, Row, Typography } from 'antd';
import { getIntegration, upsertIntegration, listAllApplications } from '../../Utils/ApiActions';

function SnowflakeIntegration(props) {

    const [displayLoading, setDisplayLoading] = useState(false);
    const [snowflakeClientId, setSnowflakeClientId] = useState('');
    const [authorizationUrl, setAuthorizationUrl] = useState('');
    const [snowflakeAccount, setSnowflakeAccount] = useState('');
    const [snowflakeWarehouse, setSnowflakeWarehouse] = useState('');
    const [snowflakeDB, setSnowflakeDatabase] = useState('');
    const [snowflakeInputTable, setSnowflakeTable] = useState('');
    const [snowflakeClientSecret, setSnowflakeClientSecret] = useState('');
    const [displaySyncLoading, setDisplaySyncLoading] = useState(false);
    const [emailColumn, setEmailColumn] = useState('');
    const [timestampColumn, setTimestampColumn] = useState('');
    const [dimensionColumn, setDimensionColumn] = useState('');    
    const [selectedApp, setSelectedApp] = useState(null);

    useEffect(() => {        
        fetchAppList();
    }, [])

    useEffect(()=>{
        if(selectedApp?.id){
            fetchSnowflakeConfiguration();
        }           
    }, [selectedApp])

    function fetchAppList(){
        listAllApplications().then(applicationList=>{
            setSelectedApp(applicationList?.data?.[0]);
        }).catch(error=>{

        })
    }

    function fetchSnowflakeConfiguration() {
        getIntegration(selectedApp.id).then(response => {
            const { data: {emailColumnName, dimensionColumnName, timestampColumnName ,snowflakeClientID, snowflakeAccount, authorizationUrl, snowflakeClientSecret, snowflakeWarehouse, snowflakeDB, snowflakeInputTable } } = response;
            setSnowflakeAccount(snowflakeAccount);
            setAuthorizationUrl(authorizationUrl);
            setSnowflakeClientSecret(snowflakeClientSecret);
            setSnowflakeClientId(snowflakeClientID);
            setSnowflakeWarehouse(snowflakeWarehouse);
            setSnowflakeDatabase(snowflakeDB);
            setSnowflakeTable(snowflakeInputTable);
            setEmailColumn(emailColumnName);
            setDimensionColumn(dimensionColumnName);
            setTimestampColumn(timestampColumnName);
        }).catch(error => {

        })
    }

    function saveSnowflakeConfiguration() {
        setDisplayLoading(true);
        upsertIntegration({
            snowflakeAccount,
            snowflakeClientID: snowflakeClientId,
            snowflakeClientSecret: snowflakeClientSecret,
            authorizationUrl: authorizationUrl,
            appID: `${selectedApp.id}`,
            snowflakeInputTable,
            snowflakeDB,
            snowflakeWarehouse,
            snowflakeOutputTable: 'lockr_verified_emails_results',
            emailColumnName: emailColumn,
            timestampColumnName: timestampColumn,
            dimensionColumnName: dimensionColumn
        }).then(response => {
            setDisplayLoading(false);
            console.log(response);
        }).catch(error => {
            setDisplayLoading(false);
            alert('Unable to upsert the integration at the moment');
        })
    }

    function syncWithSnowflake() {
        setDisplaySyncLoading(true);
        if (authorizationUrl) {
            const currentHost = 'https://'+window.location.host + '/console/snowflake-callback';
            window.location.href = authorizationUrl + '?client_id=' + encodeURIComponent(snowflakeClientId) + '&response_type=code&redirect_uri='+encodeURIComponent(currentHost);
        }
    }

    return (<SideDrawer>
        <Row
            justify="space-between"
            align="middle"
            // style={{ marginTop: 32, marginRight: 91, marginBottom: 60 }}
            className="apiandservices-newapp-btn"
        >
            <div>
                <Typography.Title className="apiandservices-heading">
                    Snowflake Integration
                </Typography.Title>
                <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                    {'Verify your email data in snowflake using lockr'}
                </label>
                <div>
                    <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                        {'Specify your account details and data warehouse.'}
                    </label>
                </div>
                <div>
                    <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                        {'Lockr will process your emails and store result in EMAILS_OUTPUTS in your snowflake data warehouse.'}
                    </label>
                </div>
            </div>
        </Row>
        <div style={{ marginLeft: 91, marginRight: 91 }}>
            <TitleText
                value={snowflakeAccount}
                placeholder={"Enter snowflake account"}
                label={"Snowflake Account"}
                marginLeft={148}
                onChange={event => setSnowflakeAccount(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                marginLeft={233}
                label={"Client ID"}
                value={snowflakeClientId}
                placeholder={"Enter Snowflake Client ID"}
                onChange={event => setSnowflakeClientId(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                marginLeft={200}
                label={"Client Secret"}
                value={snowflakeClientSecret}
                placeholder={"Enter Snowflake Client Secret"}
                onChange={event => setSnowflakeClientSecret(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                marginLeft={155}
                label={'Authorization URL'}
                value={authorizationUrl}
                placeholder={'Authorization URL'}
                onChange={event => setAuthorizationUrl(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                label={'Snowflake Warehouse'}
                value={snowflakeWarehouse}
                placeholder={'COMPUTE_WH_1'}
                marginLeft={123}
                onChange={event => setSnowflakeWarehouse(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                label={'Snowflake Database'}
                value={snowflakeDB}
                placeholder={'CUSTOMER_DB'}
                marginLeft={138}
                onChange={event => setSnowflakeDatabase(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                label={'Snowflake Table'}
                value={snowflakeInputTable}
                placeholder={'CUSTOMER_DATA'}
                marginLeft={170}
                onChange={event => setSnowflakeTable(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText
                label={'Email Column'}
                value={emailColumn}
                placeholder={'Email'}
                marginLeft={185}
                onChange={event => setEmailColumn(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <TitleText 
                label = {'Timestamp Column'}
                value = {timestampColumn}
                placeholder = {'TIMESTAMP'}
                marginLeft = {140}
                onChange = {event=>setTimestampColumn(event.target.value)}
            />
            <div style = {{marginTop: 16}}/>
            <TitleText 
                label = {'Dimension Column'}
                value = {dimensionColumn}
                placeholder = {'Category'}
                marginLeft = {143}
                onChange = {event => setDimensionColumn(event.target.value)}
            />
            <div style={{ marginTop: 16 }} />
            <Row>
                <Button onClick={() => {
                    saveSnowflakeConfiguration()
                }} block type={"primary"} style={{ width: 200 }} loading={displayLoading} className={"sign-in"}>
                    <label>{"Save"}</label>
                </Button>
                <div style={{ marginLeft: 16 }} />
                <Button onClick={() => {
                    syncWithSnowflake();
                }} block type={"primary"} style={{ width: 200 }} loading={displaySyncLoading} className={"sign-in"}>
                    <label>{"Sync Data"}</label>
                </Button>
            </Row>
        </div>
    </SideDrawer>)
}

export default SnowflakeIntegration;
import { Button, Col, Popover, Row, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ShowDeleteConfirm } from '../../App';
import CreateConnection from '../../Components/CreateConnectionModal';
import { useQuery } from '../../Components/customHook/useQuery';
import DeleteTypeModal from '../../Components/LockrDeleteModal';
import SideDrawer from '../../Components/SideDrawer';
import { createNewConnection, editConnetion, getAllConnections, stopSnowflakeIntegration } from '../../Utils/ApiActions';
import { getColumnsForConnectionLogs, mapSnowflakeStatusToColor, mapSnowflakStatusToUI } from '../../Utils/Helper';

function ConnectionList(props) {

    const query = useQuery();
    const type = query.get('type');

    const connectionListColumns = getColumnsForConnectionLogs(type, renderActionButtons, renderStatus);

    const title = `Snowflake - ${type}`;
    const subTitle = type === 'Snapshot' ?
        'Verify your email data in snowflake using lockr. Specify your account details and data warehouse.' :
        'Verify your email data in snowflake using lockr. Specify your account details and data warehouse.'
    const secondSubTitle = 'Lockr will process your emails and store results in EMAILS_OUTPUTS in your snowflake data warehouse.'

    const [displayLoading, setDisplayLoading] = useState([]);
    const [connectionList, setConnectionList] = useState([]);
    const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
    const [selectedConnectionDetails, setSelectedConnectionDetails] = useState(undefined);
    const [createMode, setCreateMode] = useState(undefined);
    const [selectedIdToDelete, setSelectedIdToDelete] = useState(undefined);

    useEffect(() => {
        fetchConnectionList();
    }, [])

    function renderStatus(status) {
        return (
            <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ height: 10, width: 10, borderRadius: 20, background: mapSnowflakeStatusToColor(status) }} />
                <label className='connection-tile-font' style={{ marginLeft: 8 }}>{mapSnowflakStatusToUI(status)}</label>
            </Row>
        )
    }

    async function deleteSnowflakeIntegration(integrationId) {
        try {
            await stopSnowflakeIntegration(true, integrationId);
            setSelectedIdToDelete(undefined);
            fetchConnectionList();
        } catch (error) {
            console.log('Unable to delete the snowflake entry at the moment:');
            console.log(error);
        }
    }

    async function stopSnowflakeIntegrationAction(integrationId) {
        try {
            await stopSnowflakeIntegration(false, integrationId);
            fetchConnectionList();
        } catch (error) {
            console.log('Unable to stop the snowflake integration at the moment');
            console.log(error);
        }
    }

    function displayStopOrDeleteButton(record) {        
        if (type === 'Snapshot') {
            if ( record.status === 'PROCESSING' || record.status === 'SENT_TO_EMAILCHECKER') {
                return <div style={{ marginLeft: 16, height: 16, width: 16 }}></div>;
            }
            return <DeleteButtonTooltip>
                <img
                    style={{
                        marginLeft: 16,
                        height: 16,
                        cursor: 'pointer',
                        width: 16,
                    }}
                    alt='stop'
                    src={require('../../assets/remove_icon.png')}
                    onClick={() => {
                        // deleteSnowflakeIntegration(record.id);
                        setSelectedIdToDelete(record.id)
                    }}
                />
            </DeleteButtonTooltip>
        } else {            
            //This is the continuous section            
            if (record.status === 'STOPPED_USER') {
                //delete icon
                return <DeleteButtonTooltip>
                    <img
                        style={{
                            marginLeft: 16,
                            height: 16,
                            cursor: 'pointer',
                            width: 16,
                        }}
                        alt='stop'
                        src={require('../../assets/remove_icon.png')}
                        onClick={() => {
                            setSelectedIdToDelete(record.id)
                        }}
                    />
                </DeleteButtonTooltip>
            } else {
                //stop icon
                return <StopButtonTooltip>
                    <img
                        style={{
                            marginLeft: 16,
                            height: 16,
                            cursor: 'pointer',
                            width: 16,
                        }}
                        alt='stop'
                        src={require('../../assets/stop_icon.png')}
                        onClick={() => {
                            stopSnowflakeIntegrationAction(record.id);
                        }}
                    />
                </StopButtonTooltip>
            }
        }
    }

    function StopButtonTooltip({ children }) {
        return <Popover
            arrowPointAtCenter={true}
            content={
                <div className='connection-action-popover' style={{
                    background: '#26282A',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    marginBottom: -8
                }}>
                    <Typography.Text className='connection-action-tooltip-title'>Stop processing</Typography.Text>
                    <Typography.Text className='connection-action-tooltip-subtitle'>Stops the active process</Typography.Text>
                    <Typography.Text style={{ marginTop: 0 }} className='connection-action-tooltip-subtitle'>entirely</Typography.Text>
                </div>
            }>
            {children}
        </Popover>
    }

    function DeleteButtonTooltip({ children }) {
        return <Popover
            arrowPointAtCenter={true}
            content={
                <div className='connection-action-popover' style={{
                    background: '#26282A',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    marginBottom: -8
                }}>
                    <Typography.Text className='connection-action-tooltip-title'>Remove entry</Typography.Text>
                    <Typography.Text className='connection-action-tooltip-subtitle'>Removes this entry from your log.</Typography.Text>
                    <Typography.Text style={{ marginTop: 0 }} className='connection-action-tooltip-subtitle'>This cannot be un-done</Typography.Text>
                </div>
            }>
            {children}
        </Popover>
    }

    function renderActionButtons(_value, record) {
        return (
            <Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button onClick={() => {
                    setCreateMode('DUPLICATE');
                    setSelectedConnectionDetails(record);
                    setShowNewConnectionModal(true);
                }} style={{ height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={require('../../assets/duplicate_icon.png')}
                        style={{ marginRight: 4, cursor: 'pointer' }}
                    />
                    <label
                        className='connection-tile-font'
                        style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            cursor: 'pointer',
                        }}
                    >Duplicate</label>
                </Button>
                <Button
                    className='connection-tile-font'
                    onClick={() => {
                        setCreateMode('EDIT');
                        setSelectedConnectionDetails(record)
                        setShowNewConnectionModal(true);
                    }}
                    style={{ height: 35, marginLeft: 8, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={require('../../assets/edit_icon.png')}
                        style={{ marginRight: 4, cursor: 'pointer' }}
                    />
                    <label
                        className='connection-tile-font'
                        style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            cursor: 'pointer',
                        }}
                    >Edit</label>
                </Button>
                {displayStopOrDeleteButton(record)}

            </Row>
        )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchConnectionList() {
        setDisplayLoading(true);
        try {
            const connectionResponse = await getAllConnections(type);
            connectionResponse.success && setConnectionList(connectionResponse.data);
        } catch (error) {
            console.log('Unable to get the connection list');
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function saveConnectionDetails(connectionDetails, startProcessing = false) {
        try {
            const {authorizationUrl, appID, dimensionColumnName, emailColumnName, frequency, snowflakeAccount, snowflakeClientID, snowflakeClientSecret, snowflakeDB, snowflakeInputTable, snowflakeOutputTable, snowflakeWarehouse, timestampColumnName, type} = connectionDetails        
            const createdConnectionDetails = await createNewConnection({authorizationUrl, appID, dimensionColumnName, emailColumnName, frequency, snowflakeAccount, snowflakeClientID, snowflakeClientSecret, snowflakeDB, snowflakeInputTable, snowflakeOutputTable, snowflakeWarehouse, timestampColumnName, type});            
            if (createdConnectionDetails.success) {
                setShowNewConnectionModal(false);
                setSelectedConnectionDetails(undefined);
                fetchConnectionList();
                startProcessing && startSnowflakeProcessing(connectionDetails, createdConnectionDetails.data.id);
            }
        } catch (error) {
            console.log('Unable to save the new connection');
            console.log(error);
        }
    }

    async function editConnectionDetails(connectionDetails, startProcessing = false) {
        try {
            await editConnetion(connectionDetails, selectedConnectionDetails.id);
            setShowNewConnectionModal(false);
            setSelectedConnectionDetails(undefined);
            fetchConnectionList();
            startProcessing && startSnowflakeProcessing(connectionDetails, selectedConnectionDetails.id);
        } catch (error) {
            console.log('Unable to edit the connection details at the moment');
            console.log(error);
        }
    }

    function startSnowflakeProcessing(connectionDetails, snowflakeIntegrationId) {
        if (connectionDetails.authorizationUrl) {
            const currentHost = 'https://' + window.location.host + '/console/snowflake-snapshot-callback';
            window.location.href = connectionDetails.authorizationUrl + `?state=${snowflakeIntegrationId}:${type}&client_id=` + encodeURIComponent(connectionDetails.snowflakeClientID) + '&response_type=code&redirect_uri=' + encodeURIComponent(currentHost);
        }
    }

    function renderEmptySection() {
        if (!displayLoading && connectionList.length <= 0) {
            return (
                <div style={{
                    marginLeft: 90,
                    marginRight: 90,
                    minHeight: '50vh',
                    background: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <img
                        alt='logo_grey'
                        src={require('../../assets/logo_grey.png')}
                    />
                    <div style={{ marginTop: 4, }} />
                    <Typography.Text style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        fontSize: '20px',
                        color: '#8B8B8B'
                    }}>+</Typography.Text>
                    <div style={{ marginTop: 4, }} />
                    <img
                        alt='snowflake_grey'
                        src={require('../../assets/Snowflake_grey.png')}
                    />
                    <div style={{ marginTop: 40, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography.Text style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '24px',
                            color: '#fff'
                        }}>Create your first connection with Snowflake</Typography.Text>
                        <Button
                            style={{ marginTop: 16 }}
                            type='primary'
                            className='btn-first-connection'
                            onClick={() => {
                                setShowNewConnectionModal(true);
                            }}>Create your first connection</Button>
                    </div>
                </div>
            )
        }
    }

    function renderConnectionListTable() {
        if (connectionList.length > 0) {
            return (
                <div style={{ background: 'black', marginLeft: 90, marginRight: 90, padding: 20}}>
                    <Typography.Text
                        style={{
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            fontSize: 20
                        }}>Connection Log</Typography.Text>
                    <div style={{ marginTop: 20 }} />
                    <div className='connection-list-table'>
                        <Table                            
                            style={{ background: 'black' }}
                            rowKey={"id"}
                            pagination={false}
                            dataSource={connectionList}
                            columns={connectionListColumns}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={18} xs={24}>
                    <Typography.Title className='apiandservices-heading'>
                        {title}
                    </Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            {subTitle}
                        </label>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            {secondSubTitle}
                        </label>
                    </div>
                </Col>
                <Col sm={6} xs={24} className="createAppButton">
                    <Button
                        type='primary'
                        className='new-app-modal-btn'
                        onClick={() => {
                            setShowNewConnectionModal(true);
                            setCreateMode('NEW');
                        }}>Create New</Button>
                </Col>
            </Row>
            {renderConnectionListTable()}
            {showNewConnectionModal && <CreateConnection
                supportsFrequency={type !== 'Snapshot'}
                isDuplicate = {createMode === 'DUPLICATE'}
                mode={createMode ?? 'NEW'}
                type={type}
                defaultConnectionDetails={selectedConnectionDetails}
                display={showNewConnectionModal}
                onCancel={() => {
                    setShowNewConnectionModal(false);
                    setSelectedConnectionDetails(undefined);
                }}
                onSave={(connectionDetails) => {
                    if (createMode === 'EDIT') {
                        editConnectionDetails({ ...connectionDetails, type }, true);
                    } else {
                        saveConnectionDetails({ ...connectionDetails, type }, true);
                    }
                }}
                onSync={(connectionDetails) => {
                    if (createMode === 'EDIT') {
                        editConnectionDetails({ ...connectionDetails, type }, true);
                    } else {
                        saveConnectionDetails({ ...connectionDetails, type }, true)
                    }
                }}
            />}
            {renderEmptySection()}
            {selectedIdToDelete !== undefined && <DeleteTypeModal
                visible={selectedIdToDelete !== undefined}
                handleOk={() => {
                    deleteSnowflakeIntegration(selectedIdToDelete);
                }}
                handleCancel={() => {
                    setSelectedIdToDelete(undefined);
                }}
                title={'Delete?'}
            />}
        </SideDrawer>
    )
}

export default ConnectionList;